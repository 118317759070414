import {
  ExportCard,
  ExportButton,
  ButtonCard,
  ExportColumn,
  ExportMessage,
  StatusButton,
  MoreIcon,
} from './styles';
import { FaMotorcycle, FaFilePdf, FaWhatsapp, FaPlus } from 'react-icons/fa';
import { useTheme } from 'styled-components';

import React from 'react';
import MainContainer from 'src/components/MainContainer';
import PaymentType from 'src/components/PaymentType';
import DeliveriesTable from 'src/components/DeliveriesTable';
import { IExportResponse, IGetDeliveries, IReceipts, TPayment } from 'src/types';
import {
  DeleteDelivery,
  FetchDeliveries,
  FetchReportStatus,
  PayReceipt,
  PostDelivery,
  PutDelivery,
} from 'src/services/deliveries';
import { useSession } from 'src/hooks/useSession';
import { debounce } from 'lodash';
import DeliveryModal, {
  ShowCreateModal,
  ShowReceiptModal,
} from 'src/components/DeliveryModal';
import DeliveryHistoricModal from 'src/components/DeliveryHistoricModal';
import InformationModal, {
  TInformationModalProps,
} from 'src/components/InformationModal';
import ConfirmationModal, {
  TConfirmationModalProps,
} from 'src/components/ConfirmationModal';
import { FetchCompanyName, FetchCustomers } from 'src/services/customers';
import LoaderModal from 'src/components/LoadingModal';
import { api_service } from 'src/services/api-service';
import axios, { AxiosError } from 'axios';
import YesNoSelector from 'src/components/YesNoSelector';
import { dateFormatBR } from 'src/utils/DateFormatter';
import Select from 'react-select';
import { custom } from 'src/components/CustomerSelectMulti';
import HoverModal from 'src/components/CustomModal';
import { Column } from '../Dashboard/styles';
import CreateDeliveryModal, { CreateReceipt } from 'src/components/CreateDeliveryModal';
import { FetchDeliverymen } from 'src/services/deliverymen';
import { isMobileDevice } from 'react-select/src/utils';
import {
  Companies,
  Container,
  Customer,
  EndDateInput,
  FilterContainer,
  Flex,
  Grid,
  Label,
  MotoboyInput,
  StartDateInput,
} from 'src/shared/styles';

const Deliveries = (): JSX.Element => {
  const { colors } = useTheme();
  const date = new Date();

  const [motoboy, setMotoboy] = React.useState('');
  const [filters, setFilters] = React.useState<IGetDeliveries>({
    paymentTypes: ['billed', 'money', 'pix'],
    client: undefined,
    motoboy: undefined,
    startdate: new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString(
      'pt-BR',
      {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      },
    ),
    finaldate: date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }),
    limit: 10,
    page: 1,
    pay: undefined,
    valueFrom: undefined,
  });
  const [showReceipt, setShowReceipt] = React.useState<ShowReceiptModal>({
    show: false,
    receipt: {} as IReceipts,
  });
  const [createReceipt, setCreateReceipt] = React.useState<ShowCreateModal>({
    show: false,
    receipt: undefined,
  });
  const [showReceiptHistoric, setShowReceiptHistoric] = React.useState<ShowReceiptModal>({
    show: false,
    receipt: {} as IReceipts,
  });
  const [infoModal, setInfoModal] = React.useState<TInformationModalProps>({
    show: false,
    message: '',
    headerText: '',
  });
  const [confirmationModal, setConfirmationModal] =
    React.useState<TConfirmationModalProps>({
      show: false,
      message: '',
      header: '',
    });

  const [loaderModal, setLoaderModal] = React.useState<TInformationModalProps>({
    show: false,
    message: '',
    headerText: '',
  });
  const [deleteDeliveryId, setDeleteDeliveryId] = React.useState(0);

  const [editedReceipt, setEditedReceipt] = React.useState<IReceipts>({} as IReceipts);

  const [payReceiptParam, setPayReceiptParam] = React.useState<IReceipts>(
    {} as IReceipts,
  );

  const [key, setKey] = React.useState('');

  const [isCompanyNameFilter, setIsCompanyNameFilter] = React.useState(false);

  const [groupingByCustomer, setGroupingByCustomer] = React.useState(true);

  const [filterHeight, setFilterHeight] = React.useState<number>();

  const { session, refreshSession } = useSession();
  const { data, isLoading, refetch } = FetchDeliveries(
    session.token,
    refreshSession,
    filters,
  );

  React.useEffect(() => {
    const height = document.getElementById('filters')?.clientHeight;

    setFilterHeight(height);
    refetch?.();
  }, [filters, refetch]);

  const { refetch: putReceipt } = PutDelivery(
    session.token,
    refreshSession,
    editedReceipt,
  );

  const { refetch: deleteReceipt } = DeleteDelivery(
    session.token,
    refreshSession,
    deleteDeliveryId,
  );

  const { data: customerList } = FetchCustomers(session.token, refreshSession);

  const { data: motoboyList } = FetchDeliverymen(session.token, refreshSession);

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    isFetching: isFetchingCompanies,
  } = FetchCompanyName(session.token, refreshSession);

  const { refetch: fileStatus } = FetchReportStatus(session.token, refreshSession, key);

  const {
    refetch: handleCreateReceipt,
    isLoading: isLoadingCreateReceipt,
    isError,
    isSuccess,
  } = PostDelivery(session.token, refreshSession);

  const { refetch: payReceipt } = PayReceipt(
    session.token,
    refreshSession,
    payReceiptParam,
  );

  const onChangeClient = (value: Array<number> | undefined) => {
    setFilters({ ...filters, client: value });
  };

  const onChangeMotoboy = debounce((value: string) => {
    setFilters({ ...filters, motoboy: value });
  }, 800);

  const onChangeStartDate = (value: string) => {
    if (value && value.length > 0) {
      const initialDate = dateFormatBR(value);
      setFilters({ ...filters, startdate: initialDate });
    }
  };

  const onChangeFinalDate = (value: string) => {
    if (value && value.length > 0) {
      const finalDate = dateFormatBR(value);
      setFilters({ ...filters, finaldate: finalDate });
    }
  };

  const onChangePayment = (value: TPayment) => {
    const index = filters.paymentTypes.findIndex((payment) => payment === value);
    if (index && index < 0) {
      setFilters({ ...filters, paymentTypes: [...filters.paymentTypes, value] });
    } else
      setFilters({
        ...filters,
        paymentTypes: filters.paymentTypes.filter((payment) => payment !== value),
      });
  };

  const onChangePage = (page: number) => {
    setFilters({ ...filters, page });
  };

  const onShowReceipt = React.useCallback((receipt: IReceipts) => {
    setEditedReceipt(receipt);
    setShowReceipt({ show: true, receipt });
  }, []);

  const onShowReceiptHistoric = React.useCallback((receipt: IReceipts) => {
    setShowReceiptHistoric({ show: true, receipt });
  }, []);

  const onDismiss = () => {
    setShowReceipt({ show: false, receipt: {} as IReceipts });
  };
  const onDismissCreate = () => {
    setCreateReceipt({ show: false, receipt: {} as IReceipts });
  };

  const onDismissHistoric = () => {
    setShowReceiptHistoric({ show: false, receipt: {} as IReceipts });
  };

  const onChangeValueFrom = (value: number | undefined) => {
    setFilters({ ...filters, valueFrom: value });
  };

  const onEditReceipt = React.useCallback(
    async (receipt: IReceipts) => {
      try {
        const response = await putReceipt(receipt);
        if (response) {
          setInfoModal({
            headerText: 'Operação Concluída',
            message: response?.data?.msg ?? 'Corrida editada com sucesso',
            show: true,
          });
          setShowReceipt({
            show: false,
            receipt: {} as IReceipts,
          });
        }
      } catch (error: any) {
        setInfoModal({
          headerText: 'Erro',
          message:
            error?.response?.data?.msg ?? 'Ocorreu um erro ao processar as informações.',
          show: true,
        });
      }
    },
    [putReceipt],
  );

  const onCreateReceipt = React.useCallback(async (receipt: CreateReceipt) => {
    try {
      const response = await handleCreateReceipt(receipt);

      if (response) {
        setInfoModal({
          headerText: 'Sucesso!',
          message: 'Corrida criada com sucesso.',
          show: true,
        });

        setCreateReceipt({
          show: false,
          receipt: undefined,
        });
      }

      if (isError) {
        setInfoModal({
          headerText: 'Erro!',
          message: 'Ocorreu um erro ao criar corrida',
          show: true,
        });
      }
    } catch (error) {
      setInfoModal({
        headerText: 'Erro!',
        message: 'Ocorreu um erro ao criar corrida',
        show: true,
      });
    }
  }, []);

  const handleCreate = () => {
    setCreateReceipt({ show: true, receipt: undefined });
  };

  const onDeleteReceipt = React.useCallback(async () => {
    try {
      setConfirmationModal({ show: false, message: '', header: '' });
      const response = await deleteReceipt();
      if (response) {
        setInfoModal({
          headerText: 'Operação Concluída',
          message: 'Corrida excluída com sucesso',
          show: true,
        });
        setShowReceipt({
          show: false,
          receipt: {} as IReceipts,
        });
      }
    } catch (error: any) {
      setInfoModal({
        headerText: 'Erro',
        message:
          error?.response?.data?.msg ?? 'Ocorreu um erro ao processar as informações.',
        show: true,
      });
    }
  }, [deleteReceipt]);

  const showOnDeleteConfirmation = (deliveryId: number) => {
    setDeleteDeliveryId(deliveryId);

    setConfirmationModal({
      message: 'Todos os dados dessa corrida serão perdidos',
      header: 'Deseja excluir essa corrida?',
      show: true,
    });
  };

  const formatDate = (dt: string) => {
    const parts = dt.split('/');

    const dateFormat = parts[2].concat('-').concat(parts[1]).concat('-').concat(parts[0]);

    return dateFormat;
  };

  const showFile = React.useCallback(
    async (url: string, keyValue: string, count: number, shareWpp: boolean) => {
      const baseURL = process.env.REACT_APP_SERVICE_PDF_URL ?? '';
      const fileURL = baseURL.concat('viewFile/').concat(keyValue);

      setKey(keyValue);
      if (keyValue.length > 0) {
        const response = await fileStatus?.();
        const file = response?.data?.data?.file;

        if (file && file.status === 'success') {
          setLoaderModal({
            headerText: '',
            message: '',
            show: false,
          });
          setInfoModal({
            headerText: 'Sucesso',
            message: 'O Relatório foi gerado com sucesso',
            show: true,
          });

          if (shareWpp) {
            onShareWpp(fileURL, filters.startdate, filters.finaldate);
          } else {
            window.open(fileURL);
          }
        } else if (file && file.status === 'error') {
          setInfoModal({
            headerText: 'Erro!',
            message: 'Ocorreu um erro ao gerar o relatório.',
            show: true,
          });
        } else {
          setTimeout(() => {
            showFile(fileURL, keyValue, count - 1, shareWpp);
          }, 3000);
        }
      } else if (count > 0) {
        setTimeout(() => {
          showFile(url, keyValue, count - 1, shareWpp);
        }, 3000);
      } else {
        setInfoModal({
          headerText: 'Erro!',
          message: 'Ocorreu um erro ao gerar o relatório.',
          show: true,
        });
      }
    },
    [fileStatus, filters.startdate, filters.finaldate],
  );

  const exportDeliveries = React.useCallback(
    (shareWpp = false) => {
      if (data?.data?.total === 0) {
        setInfoModal({
          headerText: 'Aviso!',
          message: 'Não há dados a serem exportados',
          show: true,
        });

        return;
      }

      let url = `/generate-report-client/?startdate=${filters.startdate}&finaldate=${filters.finaldate}&paymentTypes=${filters.paymentTypes}&groupingByCustomer=${groupingByCustomer}`;
      if (filters.client) url = url.concat(`&client=${filters.client}`);
      if (filters.motoboy) url = url.concat(`&motoboy=${filters.motoboy}`);
      if (filters.pay !== undefined) url = url.concat(`&pay=${filters.pay}`);

      setLoaderModal({
        headerText: 'Atenção',
        message: 'O Relatório está sendo gerado, aguarde.',
        show: true,
      });

      api_service
        .get<IExportResponse>(url, {
          headers: { ...axios.defaults.headers, 'x-access-token': session.token },
        })
        .then((response) => {
          if (response.data.url) {
            showFile(response.data.url, response.data.key, 9, shareWpp);
          }
        })
        .catch((err: Error | AxiosError) => {
          setLoaderModal({
            headerText: '',
            message: '',
            show: false,
          });
          let message = '';
          if (axios.isAxiosError(err)) {
            if (err.response?.status === 500 || err.response?.status === 502) {
              message = 'Erro interno no servidor.';
            } else if (err.response?.status === 400) {
              message = 'Ocorreu um erro ao processar as informações.';
            }
          } else {
            message = 'Erro desconhecido.';
          }
          setInfoModal({
            headerText: 'Erro',
            message,
            show: true,
          });
        });
    },
    [data?.data?.total, filters, showFile, session.token, groupingByCustomer],
  );

  const onPayDelivery = React.useCallback((receipt: IReceipts) => {
    setPayReceiptParam(receipt);
  }, []);

  const confirmOnPayDelivery = React.useCallback(async () => {
    try {
      await payReceipt(null);
      setPayReceiptParam({} as IReceipts);
    } catch {
      setInfoModal({
        headerText: 'Atenção!',
        message: 'Ocorreu um erro ao realizar esta ação!',
        show: true,
      });
    }
  }, [payReceipt]);

  const onChangeStatus = React.useCallback((payStatus: boolean) => {
    setFilters((prev) => ({
      ...prev,
      pay: prev.pay === payStatus ? undefined : payStatus,
    }));
  }, []);

  const onShareWpp = (url: string, startDate: string, finalDate: string) => {
    console.log(filters);
    const defaultMessage = `Segue sua fatura referente ao período ${dateFormatBR(
      formatDate(startDate),
    )} - ${dateFormatBR(formatDate(finalDate))}. clique no link abaixo \n ${url}`;
    window.open(`https://web.whatsapp.com/send?&text=${defaultMessage}`);
  };

  const handleShareWpp = () => {
    exportDeliveries(true);
  };

  const handleExport = () => {
    exportDeliveries(false);
  };

  const changeCompanyNameFilter = (value: boolean) => {
    setIsCompanyNameFilter(value);
    onChangeClient(undefined);
  };

  return (
    <MainContainer>
      <Container>
        <FilterContainer id="filters">
          <Grid>
            <Label>Filtros</Label>
            <Flex>
              <Flex style={{ flexDirection: 'column' }}>
                <Flex style={{ margin: 0, marginTop: 4 }}>
                  <input
                    type="checkbox"
                    onChange={(e) => changeCompanyNameFilter(e.target.checked)}
                  />
                  <span style={{ color: 'white' }}>Razão social</span>
                </Flex>
                {!isCompanyNameFilter ? (
                  <Customer
                    onSelect={(customers) => {
                      if (customers && customers.length > 0) {
                        onChangeClient(customers.map((e) => e.customerId));
                      } else {
                        onChangeClient(undefined);
                      }
                    }}
                    customerList={customerList?.data?.customers || []}
                  />
                ) : (
                  <Companies
                    companyList={
                      companies?.data?.companies.map((e, i) => ({
                        clientId: e.id,
                        id: i,
                        name: e.name,
                      })) ?? []
                    }
                    onSelect={(companiesList) => {
                      if (companiesList && companiesList.length > 0) {
                        onChangeClient(companiesList.map((e) => e.clientId));
                      } else {
                        onChangeClient(undefined);
                      }
                    }}
                  />
                )}
                {/* <Flex style={{ margin: 0, marginTop: 4 }}>
                  <input type="checkbox" />
                  <label style={{ color: 'white' }}>Razão social</label>
                </Flex>8 */}
              </Flex>
              <MotoboyInput
                text={motoboy}
                icon={<FaMotorcycle color={colors.primary} />}
                onChangeValue={(value) => {
                  onChangeMotoboy(value);
                  setMotoboy(value);
                }}
              />
            </Flex>
          </Grid>
          <Grid>
            <Label>À partir de:</Label>
            <Select
              placeholder="valor"
              id="valueFrom"
              styles={{
                ...custom,
                container(styles) {
                  return {
                    ...styles,
                    height: '30px',
                    minWidth: '140px',
                    margin: '25px 8px 0 0;',
                  };
                },
              }}
              options={[
                { label: 'Todos', value: undefined },
                { label: 20, value: 20 },
                { label: 25, value: 25 },
                { label: 30, value: 30 },
                { label: 35, value: 35 },
                { label: 40, value: 40 },
                { label: 45, value: 45 },
                { label: 50, value: 50 },
                { label: 55, value: 55 },
                { label: 60, value: 60 },
                { label: 65, value: 65 },
                { label: 70, value: 70 },
                { label: 75, value: 75 },
                { label: 80, value: 80 },
                { label: 85, value: 85 },
                { label: 90, value: 90 },
                { label: 95, value: 95 },
                { label: 100, value: 100 },
              ]}
              onChange={(e) => onChangeValueFrom(e?.value)}
            />
          </Grid>
          <Flex>
            <Grid>
              <Label>Data inicial</Label>
              <StartDateInput
                text={formatDate(filters.startdate)}
                onChangeValue={onChangeStartDate}
              />
            </Grid>
            <Grid>
              <Label>Data final</Label>
              <EndDateInput
                text={formatDate(filters.finaldate)}
                onChangeValue={onChangeFinalDate}
              />
            </Grid>
          </Flex>
          <Grid>
            <Label>Tipo de pagamento</Label>
            <Flex>
              <ButtonCard>
                <PaymentType
                  value="billed"
                  onChange={onChangePayment}
                  disabled={!filters.paymentTypes.includes('billed')}
                />
              </ButtonCard>
              <ButtonCard>
                <PaymentType
                  value="pix"
                  onChange={onChangePayment}
                  disabled={!filters.paymentTypes.includes('pix')}
                />
              </ButtonCard>
              <ButtonCard>
                <PaymentType
                  value="money"
                  onChange={onChangePayment}
                  disabled={!filters.paymentTypes.includes('money')}
                />
              </ButtonCard>
            </Flex>
          </Grid>
          <Grid>
            <Label>Status</Label>
            <Flex>
              <ButtonCard>
                <StatusButton
                  onClick={() => onChangeStatus(true)}
                  active={filters.pay === true}
                >
                  Pago
                </StatusButton>
              </ButtonCard>
              <ButtonCard>
                <StatusButton
                  onClick={() => onChangeStatus(false)}
                  active={filters.pay === false}
                >
                  Não Pago
                </StatusButton>
              </ButtonCard>
            </Flex>
          </Grid>
          <Grid>
            <Label>Ações</Label>
            <HoverModal
              content={
                <Column>
                  <Label>Exportar</Label>
                  <ExportCard>
                    <ExportColumn>
                      <ExportMessage>Agrupar por cliente?</ExportMessage>
                      <YesNoSelector
                        value={groupingByCustomer}
                        onChangeValue={(value) => setGroupingByCustomer(value)}
                        fontSize="9px"
                      />
                      <Flex>
                        <Column>
                          <ExportButton onClick={handleExport}>
                            <FaFilePdf color={colors.text} />
                            <Label>PDF</Label>
                          </ExportButton>
                          <ExportButton
                            style={{ backgroundColor: 'green', marginTop: '3px' }}
                            onClick={handleShareWpp}
                          >
                            <FaWhatsapp color={colors.text} />
                            <Label>WhatsApp</Label>
                          </ExportButton>
                        </Column>
                      </Flex>
                    </ExportColumn>
                  </ExportCard>
                  <Label>Adicionar</Label>
                  <ExportButton onClick={() => handleCreate()}>
                    <FaPlus />
                    <Label>Criar Corrida</Label>
                  </ExportButton>
                </Column>
              }
            >
              <MoreIcon>...</MoreIcon>
            </HoverModal>
          </Grid>
        </FilterContainer>
        <DeliveriesTable
          filterHeight={filterHeight ?? 0}
          receipts={data?.data?.receipts}
          totalRows={data?.data?.total}
          isLoading={isLoading}
          onChangePage={onChangePage}
          onDelete={showOnDeleteConfirmation}
          onShowReceipt={onShowReceipt}
          onShowDetails={onShowReceiptHistoric}
          onPayReceipt={onPayDelivery}
        />
        <DeliveryModal
          onEdit={onEditReceipt}
          show={showReceipt.show}
          receipt={showReceipt.receipt}
          onDismiss={onDismiss}
          customerList={customerList?.data?.customers ? customerList.data.customers : []}
        />
        <CreateDeliveryModal
          loading={isLoadingCreateReceipt}
          key="create"
          onCreate={onCreateReceipt}
          show={createReceipt.show}
          onDismiss={onDismissCreate}
          customerList={customerList?.data?.customers ? customerList.data.customers : []}
          motoboyList={motoboyList?.data?.data ? motoboyList.data.data : []}
        />
        <DeliveryHistoricModal
          onDismiss={onDismissHistoric}
          receipt={showReceiptHistoric.receipt}
          show={showReceiptHistoric.show}
        />
        <InformationModal
          headerText={infoModal.headerText}
          show={infoModal.show}
          onDismiss={() => setInfoModal({ headerText: '', message: '', show: false })}
          message={infoModal.message}
        />
        <ConfirmationModal
          show={confirmationModal.show}
          header={confirmationModal.header}
          message={confirmationModal.message}
          onCancel={() => setConfirmationModal({ show: false, header: '', message: '' })}
          onConfirm={onDeleteReceipt}
        />
        <ConfirmationModal
          show={payReceiptParam.deliveryId !== undefined}
          header={payReceiptParam.pay ? 'Remover pagamento?' : 'Confirmar pagamento?'}
          message={
            payReceiptParam.pay
              ? 'Deseja remover o pagamento da entrega'
              : 'Deseja realizar o pagamento da entrega?'
          }
          onCancel={() => setPayReceiptParam({} as IReceipts)}
          onConfirm={confirmOnPayDelivery}
        />
        <LoaderModal
          headerText={loaderModal.headerText}
          message={loaderModal.message}
          show={loaderModal.show}
        />
      </Container>
    </MainContainer>
  );
};

export default Deliveries;
