import React from 'react';
import Select, { components, OptionsType } from 'react-select';
import { ICompanyData, SelectValue } from 'src/types';
import { IconClient, Container } from './styles';

interface Props {
  companyList: ICompanyData[];
  label?: string;
  onSelect(customer: ICompanyData[]): void;
  width?: string;
  margin?: string;
  align?: string;
  fontSize?: string;
}

export const custom = {
  control: (styles: any, state: any) => ({
    ...styles,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    borderRadius: 10,
    borderColor: state.selectProps.error ? 'red' : 'transparent',
    ':hover': {
      ...styles[':hover'],
      borderColor: state.selectProps.error ? 'red' : 'transparent',
      cursor: 'pointer',
    },
    padding: 0,
    paddingLeft: 10,
    fontSize: 16,
    color: '#333',
    boxShadow: '1px 2px 5px rgba(0, 0, 0, 0.15)',
    alignContent: 'center',
  }),
  option: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: state.isSelected ? '#333' : null,
    color: state.isSelected ? '#fff' : '#070606',
    ':active': {
      ...styles[':active'],
      backgroundColor: '#333',
      color: '#fff',
    },
  }),
  container: (styles: any) => ({
    ...styles,
    height: '100%',
  }),
};

const CompanySelectMulti: React.FC<Props> = ({
  companyList,
  onSelect,
  label,
  width,
  margin,
  align,
  fontSize,
}) => {
  const [options, setOptions] = React.useState<SelectValue[]>([]);

  const onChange = React.useCallback(
    (values: OptionsType<SelectValue> | null) => {
      if (values && values.length > 0) {
        const ids = values.map((x) => parseInt(x.value, 10));
        const companies = companyList.filter((company) => ids.includes(company.id));

        if (companies.length > 0) {
          onSelect(companies);
        }
      } else {
        onSelect([]);
      }
    },
    [onSelect, companyList],
  );

  React.useEffect(() => {
    const optionsList: SelectValue[] = [];

    companyList.forEach((company) => {
      const id = company.id?.toString();
      optionsList.push({ value: id ?? '-1', label: company.name });
    });

    setOptions(optionsList.concat());
  }, [companyList]);

  return (
    <Container width={width} heigth="auto" alignContent={align} margin={margin}>
      <Select
        placeholder={label}
        isMulti
        options={options}
        onChange={onChange}
        styles={custom}
        components={{
          Control: ({ children, ...props }) => (
            <components.Control {...props}>
              <IconClient fontSize={fontSize} /> {children}
            </components.Control>
          ),
        }}
      />
    </Container>
  );
};

export default CompanySelectMulti;
