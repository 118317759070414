import {
  AccessContainer,
  AddIcon,
  AddressInput,
  Backdrop,
  Body,
  CloseButton,
  CloseIcon,
  CnpjInput,
  Column,
  ContactInput,
  Container,
  Content,
  EmailIcon,
  EmailInput,
  Header,
  HeaderText,
  Label,
  Line,
  ModalContainer,
  NameInput,
  PersonalInfoContainer,
  PersonalInfoMessage,
  PhoneIcon,
  PhoneInput,
  PrimaryInfoContainer,
  SaveButton,
  StyledModal,
  UserIcon,
  ColumnField,
  LabelError,
  CompanyNameInput,
  AddCompanyNameButton,
} from './styles';
import { maskToCnpj, maskToPhone } from 'src/utils/InputMask';

import { ICustomer } from 'src/types';
import React from 'react';
import ReactDOM from 'react-dom';
import YesNoSelector from 'src/components/YesNoSelector';
import { debounce, isEmpty } from 'lodash';
import { CustomerIcon, TrashIcon } from '../DeliveriesTable/styles';
import { Flex } from 'src/shared/styles';

export type TCustomerModalProps = {
  show: boolean;
  toggleClear: boolean;
  customer: ICustomer;
};

type TProps = {
  show: boolean;
  clearCustomer: boolean;
  currentCustomer: ICustomer;
  onDismiss(): void;
  onAdd(customerToAdd: Partial<ICustomer>): void;
  onEdit(customerToEdit: Partial<ICustomer>): void;
};

type TFormErrors = {
  [Property in keyof ICustomer]: boolean;
};

const CustomerModal: React.FC<TProps> = ({
  show = false,
  clearCustomer,
  currentCustomer,
  onDismiss,
  onAdd,
  onEdit,
}) => {
  const [customer, setCustomer] = React.useState<ICustomer>({} as ICustomer);

  const [errors, setErrors] = React.useState<TFormErrors>({} as TFormErrors);
  const [header, setHeader] = React.useState<'NOVO CLIENTE' | 'EDITAR CLIENTE'>(
    'NOVO CLIENTE',
  );

  const [companyNames, setCompanyNames] = React.useState<{ [key: number]: string }>({});

  const onChange = React.useCallback((key: keyof ICustomer, value: any) => {
    setCustomer((prev) => ({ ...prev, [key]: value }));
    setErrors((prev) => ({ ...prev, [key]: false }));
  }, []);

  const onSave = () => {
    const submitErrors = {
      name: true,
      cnpj: true,
      address: true,
      contact: true,
      phone: true,
      email: true,
    } as TFormErrors;
    let hasError = false;
    Object.entries(customer).forEach(([key, value]) => {
      submitErrors[key as keyof ICustomer] = !value || value === '';
    });

    submitErrors.enable = false;
    submitErrors.customerId = false;
    submitErrors.companyName = false;

    Object.entries(submitErrors).forEach(([, value]) => {
      hasError = hasError || !!value;
    });

    if (hasError) {
      console.log(hasError);
      setErrors({ ...submitErrors });
      return;
    }

    if (header === 'NOVO CLIENTE') {
      onAdd({
        ...customer,
        cnpj: customer.cnpj.replace(/\D/g, ''),
        companyName: Object.values(companyNames).join(','),
      });
    } else {
      onEdit({
        ...customer,
        cnpj: customer.cnpj.replace(/\D/g, ''),
        companyName: Object.values(companyNames).join(','),
      });
    }
  };

  const onChangeValueCompanyName = (value: string, index: number) => {
    setCompanyNames((prev) => ({ ...prev, [index]: value }));
  };

  const onAddCompanyName = () => {
    setCompanyNames((prev) => {
      const newKey =
        Object.keys(prev).length > 0 ? Math.max(...Object.keys(prev).map(Number)) + 1 : 0;
      return { ...prev, [newKey]: '' };
    });
  };

  const onRemoveValueCompanyName = (index: number) => {
    setCompanyNames((prev) => {
      const newCompanyNames = { ...prev };
      delete newCompanyNames[index];
      return newCompanyNames;
    });
  };

  React.useEffect(() => {
    setCompanyNames({});
    if (!isEmpty(currentCustomer)) {
      setHeader('EDITAR CLIENTE');
      setCustomer({ ...currentCustomer });
      setCompanyNames(currentCustomer.companyName?.split(',') ?? []);
      setErrors({} as TFormErrors);
    } else {
      setHeader('NOVO CLIENTE');
      setCustomer({} as ICustomer);
      setErrors({} as TFormErrors);
    }
  }, [currentCustomer]);

  React.useEffect(() => {
    setCustomer({} as ICustomer);
    setErrors({} as TFormErrors);
  }, [clearCustomer]);

  React.useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      const key = event.key || event.keyCode;
      if ((key === 27 || key === 'Escape') && show) {
        onDismiss();
      }
    };

    show
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');

    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [onDismiss, show]);

  const modal = (
    <Container>
      <Backdrop onClick={onDismiss} />
      <ModalContainer aria-modal tabIndex={-1} role="dialog">
        <StyledModal>
          <Header>
            <HeaderText>
              <AddIcon />
              {header}
            </HeaderText>
            <CloseButton onClick={onDismiss}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <Content>
            <Body>
              <PrimaryInfoContainer>
                <UserIcon />
                <Column>
                  <ColumnField>
                    <NameInput
                      error={!!errors.name}
                      text={customer.name}
                      onChangeValue={(value) => onChange('name', value)}
                    />
                    <LabelError>{!!errors.name && 'Nome é obrigatório'}</LabelError>
                  </ColumnField>
                  <ColumnField>
                    {Object.keys(companyNames).map((key) => (
                      <Flex
                        key={`${key}`}
                        style={{ width: '100%', alignItems: 'center', gap: '4px' }}
                      >
                        <CompanyNameInput
                          key={key}
                          error={companyNames[Number(key)].length === 0}
                          text={companyNames[Number(key)]}
                          onChangeValue={(value) =>
                            onChangeValueCompanyName(value, Number(key))
                          }
                        />
                        <TrashIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => onRemoveValueCompanyName(Number(key))}
                        />
                      </Flex>
                    ))}
                    <LabelError>{!!errors.name && 'Nome é obrigatório'}</LabelError>
                    <AddCompanyNameButton
                      disabled={
                        Object.values(companyNames).filter((e) => e.length === 0).length >
                        0
                      }
                      onClick={onAddCompanyName}
                    >
                      Adicionar Razão Social
                    </AddCompanyNameButton>
                  </ColumnField>
                  <Line>
                    <ColumnField>
                      <EmailInput
                        error={!!errors.email}
                        icon={<EmailIcon />}
                        text={customer.email}
                        onChangeValue={(value) => onChange('email', value)}
                      />
                      <LabelError>{!!errors.email && 'Email é obrigatório'}</LabelError>
                    </ColumnField>
                    <ColumnField>
                      <PhoneInput
                        error={!!errors.phone}
                        icon={<PhoneIcon />}
                        text={customer.phone}
                        onChangeValue={(value) => onChange('phone', maskToPhone(value))}
                      />
                      <LabelError>
                        {!!errors.phone && 'Telefone é obrigatório'}
                      </LabelError>
                    </ColumnField>
                  </Line>
                </Column>
              </PrimaryInfoContainer>
              <PersonalInfoContainer>
                <PersonalInfoMessage>INFORMAÇÕES ADICIONAIS</PersonalInfoMessage>
                <ColumnField>
                  <AddressInput
                    error={!!errors.address}
                    text={customer.address}
                    onChangeValue={(value) => onChange('address', value)}
                  />
                  <LabelError>{!!errors.address && 'Endereço é obrigatório'}</LabelError>
                </ColumnField>
                <ColumnField>
                  <CnpjInput
                    error={!!errors.cnpj}
                    text={maskToCnpj(customer.cnpj)}
                    onChangeValue={(value) => onChange('cnpj', maskToCnpj(value))}
                  />
                  <LabelError>{!!errors.cnpj && 'CNPJ é obrigatório'}</LabelError>
                </ColumnField>
                <ColumnField>
                  <ContactInput
                    error={!!errors.contact}
                    text={customer.contact}
                    onChangeValue={(value) => onChange('contact', value)}
                  />
                  <LabelError>{!!errors.contact && 'Contato é obrigatório'}</LabelError>
                </ColumnField>
                <AccessContainer>
                  <Label>Liberado para entregas:</Label>
                  <YesNoSelector
                    value={!!customer.enable}
                    onChangeValue={(value) => onChange('enable', value)}
                  />
                </AccessContainer>
              </PersonalInfoContainer>
              <SaveButton onClick={onSave}>SALVAR</SaveButton>
            </Body>
          </Content>
        </StyledModal>
      </ModalContainer>
    </Container>
  );
  return show ? ReactDOM.createPortal(modal, document.body) : null;
};

export default CustomerModal;
