import CompanySelectMulti from 'src/components/CompanySelect';
import CustomerSelectMulti from 'src/components/CustomerSelectMulti';
import Input from 'src/components/Input';
import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  padding: 10px;
`;

export const Flex = styled.div`
  display: flex;
`;

export const Container = styled.div`
  width: calc(100% - 75px);
  height: calc(100vh - 75px);
`;

export const FilterContainer = styled.div`
  width: calc(100% - 20px);
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 10px;
  margin: 10px 10px 0px 10px;
  background-color: ${({ theme }) => theme.colors.cardBackground};
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.text};
`;

export const ClientInput = styled(Input).attrs({
  placeholder: 'clientes',
  id: 'client',
  type: 'text',
  autocomplete: 'on',
  margin: '25px 8px 0 0',
  width: '180px',
  height: '30px',
})`
  font-size: 16px;
`;

export const MotoboyInput = styled(Input).attrs({
  placeholder: 'motoqueiros',
  id: 'motoboy',
  type: 'text',
  autocomplete: 'on',
  margin: '25px 8px 0 0',
  width: '170px',
})`
  font-size: 16px;
`;

export const StartDateInput = styled(Input).attrs({
  placeholder: 'data inicial',
  id: 'motoboy',
  type: 'date',
  autocomplete: 'on',
  margin: '25px 8px 0 0',
  width: '170px',
})`
  font-size: 16px;
  height: 39px;
`;

export const EndDateInput = styled(Input).attrs({
  placeholder: 'data final',
  id: 'motoboy',
  type: 'date',
  autocomplete: 'on',
  margin: '25px 8px 0 0',
  width: '170px',
})`
  font-size: 16px;
  height: 39px;
`;

export const Customer = styled(CustomerSelectMulti).attrs({
  margin: '0 8px 0 0',
  width: '200px',
  height: '30px',
  fontSize: '14px',
  label: 'clientes',
})`
  height: 30px !important;
`;

export const Companies = styled(CompanySelectMulti).attrs({
  margin: '0 8px 0 0',
  width: '200px',
  height: '30px',
  fontSize: '14px',
  label: 'razão social',
})`
  height: 30px !important;
`;

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.mainBackground};
  width: 100%;
  height: 100%;
`;

export const EmptyMessage = styled.div`
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: ${({ theme }) => theme.colors.text};
`;
