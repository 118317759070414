import { Container, Icon, InputText } from './styles';

import React from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  margin?: string;
  width?: string;
  text: string;
  icon?: React.ReactNode;
  onChangeValue?(value: string): void;
  ref?: React.MutableRefObject<HTMLInputElement | null>;
}

const Input: React.FC<Props> = ({
  margin,
  width,
  icon,
  text = '',
  onChangeValue,
  ref,
  ...rest
}) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChangeValue) {
      onChangeValue(event.target.value);
    }
  };

  return (
    <Container width={width} margin={margin}>
      {icon && <Icon>{icon}</Icon>}
      <InputText {...rest} ref={ref} value={text} onChange={onChange} />
    </Container>
  );
};

export default Input;
